.qr-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 440px;
    padding: 10px;
    margin: 70px auto;
}

.qr-page > * {
    display: flex;
    justify-content: center;
}

.download-link:hover {
    cursor: pointer;
    text-decoration: underline;
}