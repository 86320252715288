@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header {
  align-items: center;
  background-color: #053852;
  box-shadow: 0 2px 0 rgba(0,0,0,.1);
  color: #fff;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 10px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.header__logo {
  background-color: transparent;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;
  height: 35px;
  width: 60px;
  margin: 0px auto;
}

.error-box {
  margin: 70px auto;
  text-align: center;
  font-size: 36px;
  text-transform: uppercase;
  color: #6a6a6a;
  font-weight: 700;
}

.error-code {
  font-size: 193px;
  line-height: 195px;
  display: block;
  letter-spacing: -7px;
  margin: 0 0 34px;
}

.error-message {
  font-size: 24px;
}

.error-message--small {
  font-size: 16px;
}