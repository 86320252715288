.recipes {
    background-color: #dbdcdc;
    border-radius: 6px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 440px;
    padding: 10px;
    margin: 70px auto;
}

.recipe__icon {
    background-color: #4d4d4d;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 38px 38px;
    border-radius: 3px 0 0 3px;
    border-right: 1px solid #fff;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 56px;
    -webkit-transition: background-color .5s cubic-bezier(.19,1,.22,1);
    transition: background-color .5s cubic-bezier(.19,1,.22,1);
    width: 56px;
}

.recipe {
    width: 100%;
    padding: 1px;
}

.recipe__content {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    max-width: 500px;
    width: 100%;
}

.recipe__label {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f6f6f6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    padding: 0 20px;
    -webkit-transition: background-color .5s cubic-bezier(.19,1,.22,1),color .5s cubic-bezier(.19,1,.22,1);
    transition: background-color .5s cubic-bezier(.19,1,.22,1),color .5s cubic-bezier(.19,1,.22,1);
}

.recipe__price {
    width: 50px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f6f6f6;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    padding: 0 20px;
    -webkit-transition: background-color .5s cubic-bezier(.19,1,.22,1),color .5s cubic-bezier(.19,1,.22,1);
    transition: background-color .5s cubic-bezier(.19,1,.22,1),color .5s cubic-bezier(.19,1,.22,1);
}